import axios, {
  getDataRequest,
  postDataRequest,
  // formatQueryResponse,
} from "../helper/AxiosHelper";
import ApiHelper from "./ApiHelper";

const loginWithAccessToken = async (accessToken) => {
  const data = {
    access_token: accessToken,
  };

  const response = await axios.post("/admin/loginWithAccessToken", data);

  return ApiHelper.processResponse(response);
};


const refreshOrganizationAccessToken = async (orgId, adminUserId) => {
  const data = {
    organization_id: orgId,
    admin_user_id: adminUserId,
  };

  const response = await axios.post("/admin/refreshOrganizationAccessToken", data);

  return ApiHelper.processResponse(response);
};

const login = async (account, password) => {
  const data = {
    account: account,
    password: password,
  };

  const response = await axios.post("/admin/login", data);

  return ApiHelper.processResponse(response);
};

const logout = async (email, password) => {
  const data = {};

  const response = await axios.post("/admin/logout", data);

  return ApiHelper.processResponse(response);
};

const checkSkillInput = async (eventID, qrCode, skill, score, skillInputData) => {
  const data = {
    event_id: eventID,
    qrcode_id: qrCode,
    skill_code: skill,
    skill_score: score,
    skill_input_data: skillInputData,
  };

  const url = `/admin/checkSkillInput`;

  const response = await axios.post(url, data);

  return ApiHelper.processResponse(response);
};

const submitScoreQRCode = async (eventID, qrCode, skillCode, skillScore) => {
  const data = {
    event_id: eventID,
    qrcode_id: qrCode,
    skill_code: skillCode,
    skill_score: skillScore,
  };

  const url = `/admin/submitScoreQR`;

  const response = await axios.post(url, data);

  return ApiHelper.processResponse(response);
};

// Depm
const submitScore = async (memberID, eventID, skillCode, skillScore, skillInputData) => {

  const skillInputDataForServer = {
    values: skillInputData.valueArray,
  }

  const data = {
    member_id: memberID,
    event_id: eventID,
    skill_code: skillCode,
    skill_score: skillScore,
    skill_input_data: skillInputDataForServer,
  };

  console.debug("inputData: ", data);

  const url = `/admin/submitScore`;

  const response = await axios.post(url, data);

  return ApiHelper.processResponse(response);
};

const loadEventLeaderboardCallback = async (
  eventID,
  date,
  timeslotID,
  callback
) => {
  console.debug("loadEventLeaderboardCallback: ", eventID, date, timeslotID);

  const result = await loadEventLeaderboard(eventID, date, timeslotID);

  console.debug("loadEventLeaderboardCallback: result: ", result);

  if (callback) {
    console.debug("loadEventLeaderboardCallback: result: ", result);
    if (result == null || result === undefined) {
      console.error("invalid result");
      return;
    }
    callback(result);
  }
};

const loadEventLeaderboard = async (
  eventID,
  date,
  timeslotID,
  showDetail = false
) => {
  const url = `/admin/eventLeaderboard/${eventID}?date=${date}&timeslot_id=${timeslotID}&show_detail=${showDetail}`;
  const data = {};
  console.log("loadEventLeaderboard: ", url);

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const loadEventOverallLeaderboardCallback = async (eventID, date, callback) => {
  const result = await loadEventOverallLeaderboard(eventID, date);
  if (callback) {
    console.debug("loadEventOverallLeaderboard: result: ", result);
    if (result == null || result === undefined) {
      console.error("invalid result");
      return;
    }
    callback(result);
  }
};

const loadEventOverallLeaderboard = async (eventID, date) => {
  const url = `/admin/eventOverallLeaderboard/${eventID}?date=${date}`;
  const data = {};

  console.log("loadEventOverallLeaderboard: ", url);
  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const loadSingleEventLeaderboardCallback = async (
  eventID,
  date,
  timeslotID,
  skillCode,
  callback = () => { }
) => {
  const result = await loadSingleEventLeaderboard(
    eventID,
    date,
    timeslotID,
    skillCode
  );

  if (callback === undefined) {
    console.warn("loadSingleEventLeaderboardCallback: callback is undefined");
    return;
  }

  if (result == null || result === undefined || result.length === 0) {
    console.debug("loadSingleEventLeaderboardCallback: no result");
    callback(null);
    return;
  }

  callback(result[0]);
  return result[0];
};

const loadSingleEventLeaderboard = async (
  eventID,
  date,
  timeslotID,
  skillCode
) => {
  const url = `/admin/eventLeaderboard/${eventID}?skill=${skillCode}&date=${date}&timeslot_id=${timeslotID}`;
  const data = {};

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const loadSingleEventOverallLeaderboardCallback = async (
  eventID,
  date,
  queryCode,
  callback
) => {
  const result = await loadSingleEventOverallLeaderboard(
    eventID,
    date,
    queryCode
  );
  if (callback) {
    console.debug("loadEventOverallLeaderboard: result: ", result);
    if (result == null || result === undefined) {
      console.error("invalid result");
      return;
    }
    callback(result);
  }

  return result;
};

const loadSingleEventOverallLeaderboard = async (eventID, date, queryCode) => {
  const url = `/admin/singleEventOverallLeaderboard/${eventID}?date=${date}&query_code=${queryCode}`;
  const data = {};

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const previewHealthData = async (eventID, fileObject) => {
  if (fileObject === null || fileObject === undefined) {
    throw new Error("File is not selected");
  }

  if (fileObject.name.endsWith(".csv") === false) {
    throw new Error("請上載 CSV 檔案");
  }

  const url = `/admin/previewHealthData`;

  const formData = new FormData();
  formData.append("event_id", eventID);
  formData.append("file", fileObject);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const response = await axios.post(url, formData, options);

  return ApiHelper.processResponse(response);
};

const importHealthData = async (eventID, fileObject) => {
  if (fileObject === null || fileObject === undefined) {
    throw new Error("File is not selected");
  }

  if (fileObject.name.endsWith(".csv") === false) {
    throw new Error("請上載 CSV 檔案");
  }

  const url = `/admin/importHealthData`;

  const formData = new FormData();
  formData.append("event_id", eventID);
  formData.append("file", fileObject);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const response = await axios.post(url, formData, options);

  return ApiHelper.processResponse(response);
};

// const
// const importHealthData = async (eventID, fileObject) => {

/// --- Get Organization
const getOrgnizationDetailCallback = (orgId, callback) => {
  const url = `/organization/show/${orgId}`;
  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }

    // _data.login_access_token = _data.login_access_token; // ken: XXX: Hardcode first


    callback(true, "", _data);
    // queryResultSetter(() => formatQueryResponse(_data));
    // backdropSetter(() => false);
  });

  // let urlWithQuery = `${fetchDataConfig.url}?query=${JSON.stringify(query)}`;
  // if ("extraQuery" in fetchDataConfig) {
  //   fetchDataConfig.extraQuery.forEach((extraQueryParma) => {
  //     if (urlParma[extraQueryParma.field] === undefined) return;
  //     urlWithQuery += `&${extraQueryParma.key}=${
  //       urlParma[extraQueryParma.field]
  //     }`;
  //   });
  // }
  // getDataRequest(urlWithQuery, (isSuccess, _data) => {
  //   if (!isSuccess) {
  //     return false;
  //   }
  //   queryResultSetter(() => formatQueryResponse(_data));
  //   backdropSetter(() => false);
  // });
  //
  //getDataRequest(`organization/show/${orgId}`, (isSuccess, data) => {
  // const url = `/admin/getOrgnizationID/${orgId}`;
  // const data = {};

  // const response = await axios.get(url, data);

  // return ApiHelper.processResponse(response);
};

const getOrgnizationListCallback = (callback) => {
  const url = `/organization`;
  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }

    callback(true, "", _data);
    // queryResultSetter(() => formatQueryResponse(_data));
    // backdropSetter(() => false);
  });
};

const batchInsertSkillScore = async (formData, callback) => {
  const response = await postDataRequest(
    "/admin/batchSubmitScore",
    formData,
    (isSuccess, _data) => {
      if (!isSuccess) {
        let msg = _data;
        callback(false, msg, null); // _data is error message
        return;
      }

      callback(true);
      // queryResultSetter(() => formatQueryResponse(_data));
      // backdropSetter(() => false);
    }
  );

  return ApiHelper.processResponse(response);
};

const getEquCateListCallback = (callback) => {
  const url = `/equipmentCategory`;
  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }

    callback(true, "", _data);
    // queryResultSetter(() => formatQueryResponse(_data));
    // backdropSetter(() => false);
  });
};

const insertEquipmentData = async (formData, callback) => {
  const response = await postDataRequest(
    "/equipment/create",
    formData,
    (isSuccess, _data) => {
      if (!isSuccess) {
        let msg = _data;
        callback(false, msg, null); // _data is error message
        return;
      }

      callback(true);
      // queryResultSetter(() => formatQueryResponse(_data));
      // backdropSetter(() => false);
    }
  );

  return ApiHelper.processResponse(response);
};

const updateEquipmentData = async (equId, formData, callback) => {
  const response = await postDataRequest(
    "/equipment/update/" + equId,
    formData,
    (isSuccess, _data) => {
      if (!isSuccess) {
        let msg = _data;
        callback(false, msg, null); // _data is error message
        return;
      }

      callback(true);
      // queryResultSetter(() => formatQueryResponse(_data));
      // backdropSetter(() => false);
    }
  );

  return ApiHelper.processResponse(response);
};



const getMemberEventHealthInfoCallback = (eventID, qrCode, callback) => {
  const url = `/memberEventHealthInfo/getInfo?event_id=${eventID}&qrcode=${qrCode}`;

  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }
    console.log("getMemberEventHealthInfoCallback: ", _data);

    callback(true, "", _data);
  });
};


const updateMemberEventHealthInfo = async (eventID, data, callback) => {
  const url = `/memberEventHealthInfo/updateInfo`;

  data.event_id = eventID;

  const response = await postDataRequest(
    url,
    data,
    (isSuccess, message, responseData) => {
      console.log("updateMemberEventHealthInfo1111: ",
        isSuccess, " responseData:", responseData);
      if (!isSuccess) {
        callback(false, message, null); // _data is error message
        return;
      }

      let { success, msg, result } = responseData;
      if (success === false) {
        callback(false, msg, null); // _data is error message
        return;
      }
      // console.log("****** updateMemberEventHealthInfo: ", success
      //   , msg, result);

      callback(true, "", result.record);
      // queryResultSetter(() => formatQueryResponse(_data));
      // backdropSetter(() => false);
    }
  );

  return ApiHelper.processResponse(response);
};

const exportHealthAndSkillSummary = (eventID, callback) => {
  const url = `/event/exportFindEventHealthAndSkillSummary?event_id=${eventID}`;

  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }
    console.log("exportHealthAndSkillSummary: ", _data);

    callback(true, "", _data);
  });
};

// getEventRedemptionDetailByQRCode
const getEventRedemptionDetailByQRCode = async (eventID, qrCode) => {
  const url = `/admin/getEventRedemptionDetailByQRCode?event_id=${eventID}&qrcode=${qrCode}`;
  const formData = {}

  const response = await axios.get(url, formData);

  return ApiHelper.processResponse(response);
};

const getRedemptionInfoByQRcode = async (qrcode) => { // this is redeem qrCode 

  const url = `/admin/getRedemptionInfoByQRCode?qrcode=${qrcode}`;

  const formData = {}

  const response = await axios.get(url, formData);

  return ApiHelper.processResponse(response);
};

const markRedeemedByID = async (redemptionId) => {
  const url = `/admin/markRedeemedByID`;

  const formData = {
    redemption_id: redemptionId,
  }

  const response = await axios.post(url, formData);

  return ApiHelper.processResponse(response);
}


const changePasswordByPhoneNum = async (phoneNum, newPassword) => {
  const url = `/admin/changePasswordByPhoneNum`;

  const formData = {
    phone_num: phoneNum,
    new_password: newPassword,
    access_token: "XYZSDFA123124"   // ken: XXX: Hardcode first
  }

  const response = await axios.post(url, formData);

  return ApiHelper.processResponse(response);
}

// ----------------------------------------------------------------------
const loadCoSportTopRanking = async () => {
  const url = `/admin/analytic/skillTopOrganization`;
  const data = {};
  console.log("loadCoSportTopRanking: ", url);

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};


const loadCoSportAnalytics = async (orgType) => {
  const url = `/admin/analytic/skillStatistics?org_type=${orgType}`;
  const data = {};
  console.log("loadCoSportTopRanking: ", url);

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};


const loadCoSportSkillScore = async (eventID, skillCode) => {
  const url = `/admin/analytic/coSportSkillScore?event_id=${eventID}&skill_code=${skillCode}`;
  const data = {};
  console.log("loadCoSportSkillScore: ", url);

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const loadOrgMissionSettingList = async (orgID) => {

  const url = `/organization/${orgID}/missing_settings`;
  const data = {};
  console.log("loadOrgMissionSettingList: ", url);
  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

/// ----------------------------------------------------------------
/// Organisation Mission Settings 
/// 

const loadOrgMissionSettingListCallback = (orgID, callback) => {
  const url = `/organization/${orgID}/missing_settings`;
  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }

    callback(true, "", _data);
  });
};


const createMissionSettingCallback = async (formData, callback) => {
  const response = await postDataRequest(
    "/organization/createOrgMissionSetting",
    formData,
    (isSuccess, _data, result) => {
      if (!isSuccess) {
        let msg = _data;
        console.log("createMissionSettingCallback: _data=", _data, " result=", result);
        callback(false, msg, null); // _data is error message
        return;
      }

      let record = result.result;
      if (record === null || record === undefined) {
        callback(false, "No record return", null);
        return;
      }

      callback(true, "", record);
    }
  );

  return ApiHelper.processResponse(response);
};


const updateMissionSettingCallback = async (formData, callback) => {
  const response = await postDataRequest(
    "/organization/updateOrgMissionSetting",
    formData,
    (isSuccess, _data, result) => {
      if (!isSuccess) {
        let msg = _data;
        callback(false, msg, null); // _data is error message
        return;
      }

      let record = result.result;
      if (record === null || record === undefined) {
        callback(false, "No record return", null);
        return;
      }

      console.log("updateMissionSettingCallback: ", record);
      callback(true, "", record);
    }
  );

  return ApiHelper.processResponse(response);
};



const getOrgMissionSettingCallback = (orgID, callback) => {
  // {{host}}/api/organization/getOrgMissionSetting/1
  const url = `/organization/getOrgMissionSetting/${orgID}`;
  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }

    callback(true, "", _data);
  });
};

const getDashboardDataCallback = async (callback) => {

  const url = `/admin/getDashboardData`;

  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }

    callback(true, "", _data);
  });

}

// ----------------------------------------------------------------------

export const AdminApi = {
  login,
  logout,
  getDashboardDataCallback,
  getOrgMissionSettingCallback,
  createMissionSettingCallback,
  updateMissionSettingCallback,
  loginWithAccessToken,
  loadCoSportSkillScore,
  loadCoSportAnalytics,
  loadCoSportTopRanking,
  refreshOrganizationAccessToken,
  changePasswordByPhoneNum,
  markRedeemedByID,
  getRedemptionInfoByQRcode,
  exportHealthAndSkillSummary,
  updateMemberEventHealthInfo,
  getMemberEventHealthInfoCallback,
  previewHealthData,
  importHealthData,
  getOrgnizationListCallback,
  getOrgnizationDetailCallback,
  loadEventLeaderboard,
  loadEventLeaderboardCallback,
  loadSingleEventLeaderboardCallback,
  loadEventOverallLeaderboard,
  loadEventOverallLeaderboardCallback,
  loadSingleEventOverallLeaderboard,
  loadSingleEventOverallLeaderboardCallback,
  checkSkillInput,
  submitScore,
  submitScoreQRCode,
  batchInsertSkillScore,
  getEquCateListCallback,
  insertEquipmentData,
  updateEquipmentData,
  getEventRedemptionDetailByQRCode,
  loadOrgMissionSettingList,
  loadOrgMissionSettingListCallback,
};
