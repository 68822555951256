import { Navigate, useRoutes } from "react-router-dom";
/** Layout */
import DashboardLayout from "../layouts/dashboard";
import SimpleLayout from "../layouts/simple";
/** Auth */
import AuthProvider from "../components/AuthProvider";
import LoginPage from "../views/LoginPage";
import DirectSchoolLoginPage from "../views/DirectSchoolLoginPage";
/** System */
import Page404 from "../views/Page404";
import DemoPage from "../views/DemoPage";
import CoSportRankingPage from "../views/CoSportRankingPage";
import TestRoutes from "./TestRoute";
/** LeaderBoard */
import LeaderBoardPage from "../views/LeaderBoardPage";
import OverallLeaderBoardPage from "../views/OverallLeaderBoardPage";
import CoSportAnalyticsPage from "../views/CoSportAnalyticsPage";

/** Member */
import AdminLeaderboardPage from "../views/AdminLeaderboardPage";
import AdminStatisticPage from "../views/AdminStatisticPage";
import AdminDataQueryPage from "../views/AdminDataQueryPage";
import MemberQRPage from "../views/MemberQRPage";
import RedemptionPage from "../views/RedemptionPage";
import CapsuleRedemption from "../sections/event/CapsuleRedemption";
/** Home */
import HomePage from "../views/HomePage";
/** Skill */
import SkillListPage from "../views/SkillListPage";
import SkillInputPage from "../views/SkillInputPage";
import TanitaImportPage from "../views/TanitaImportPage";
/** Health */
import HealthInputPage from "../views/HealthInputPage";

/** Route Section */
import OrganizationRoute from "./organizationRoute";
import EquipmentRoute from "./equipmentRoute";
import SamplePage from "../views/samplePage";
import UserRoute from "./userRoute";

import { isAdminUser } from "../helper/PermissionHelper";
import {
  getLocalStorageUser,
  getlocalStorageUserObject,
  removeLocalStorageUser,
} from "../helper/LocalStorageHelper";
import { auth } from "./routerHelper";
import ChangeMemberPasswordPage from "../views/ChangeMemberPasswordPage";
import MainEventList from "../views/MainEventList";

// ----------------------------------------------------------------------

export default function Router() {
  const user = getlocalStorageUserObject();

  const isAdmin = isAdminUser(user);
  // console.debug("Router.user: ", user);
  console.debug("isAdmin: ", isAdmin);

  const rootPage = isAdmin ? "/home" : "/user/dashboard";

  const routes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to={rootPage} />,
          index: true,
        },
        {
          path: "home",
          element: auth(<HomePage />),
          //   <AuthProvider>
          //     <HomePage />
          //   </AuthProvider>
          // ),
        },
        {
          path: "main-event-list", element: auth(<MainEventList />),
        },
        { path: "skill-list/:event_id", element: <SkillListPage /> },
        { path: "skill-input/:skill_code", element: <SkillInputPage /> },
        { path: "health-input/:event_id", element: <HealthInputPage /> },
        { path: "tanita-import", element: <TanitaImportPage /> },
        { path: "redemption-page", element: auth(<CapsuleRedemption />) },
        { path: "change-member-password", element: auth(<ChangeMemberPasswordPage />) },
        { path: "member-qr", element: <MemberQRPage /> },
        { path: "demo", element: <DemoPage /> },
        { path: "sample", element: <SamplePage /> },
        {
          path: "adminLeaderboard",
          element: auth(<AdminLeaderboardPage />),
        },
        {
          path: "adminStatistic",
          element: auth(<AdminStatisticPage />),
        },
        //AdminDataQueryPage
        {
          path: "adminDataQuery",
          element: auth(<AdminDataQueryPage />),
        },
      ],
    },
    OrganizationRoute,
    EquipmentRoute,
    UserRoute,
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "direct_school_login",
      element: <DirectSchoolLoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    TestRoutes,
    {
      path: "/overall-dashboard/:eventId",
      element: <OverallLeaderBoardPage />,
    },
    {
      path: "/dashboard/:eventId",
      element: <LeaderBoardPage />,
    },
    {
      path: "/cosport-ranking",
      element: <CoSportRankingPage />,
    },
    {
      path: "/cosport-analytics",
      element: <CoSportAnalyticsPage />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
