// component
import SvgColor from "../../../components/svg-color";

import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from '@mui/icons-material/BarChart';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import QrCodeIcon from "@mui/icons-material/QrCode";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SearchIcon from '@mui/icons-material/Search';
import RedeemIcon from '@mui/icons-material/Redeem';
import PasswordIcon from '@mui/icons-material/Password';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import StarsIcon from '@mui/icons-material/Stars';
import ListAltIcon from '@mui/icons-material/ListAlt';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);


export const userNavConfig = [
  {
    title: "主頁", //'home',
    path: "/user/dashboard",
    icon: <HomeIcon />,
  },

  {
    title: "學生列表", // 'LEADERBOARD',
    path: "/user/members",
    icon: <PeopleIcon />,
  },
  {
    title: "共創排行榜", // 'LEADERBOARD',
    path: "/cosport-ranking",
    icon: <LeaderboardIcon />,
  },
  {
    title: "共創數據綜覽", // 'LEADERBOARD',
    path: "/cosport-analytics",
    icon: <StackedBarChartIcon />,
  },
  {
    title: "排行榜資料", // 'LEADERBOARD',
    path: "/user/leaderboard",
    icon: <MilitaryTechIcon />,
  },

  {
    title: "活動統計", // 'STATISTIC',
    path: "/user/statistic",
    icon: <EqualizerIcon />,
  },
  {
    title: "數據查詢", // 'LEADERBOARD',
    path: "/user/dataQuery",
    icon: <SearchIcon />,
  },
  //leaderboard
];

export const adminNavConfig = [
  {
    title: "主頁", //'home',
    path: "/home",
    icon: <HomeIcon />,
  },
  {
    title: "活動列表", //'home',
    path: "/main-event-list",
    icon: <ListAltIcon />,
  },
  {
    title: "機構管理", //'org',
    path: "/organizations",
    icon: <ApartmentIcon />,
  },
  {
    title: "換領獎勵", // 'LEADERBOARD',
    path: "/redemption-page",
    icon: <RedeemIcon />,
  },
  {
    title: "設備管理", //'equipment',
    path: "/equipment-categories",
    icon: <HomeRepairServiceIcon />,
  },
  // {
  //   title: "活動管理", //'event',
  //   path: "/events",
  //   icon: <ApartmentIcon/>,
  // },
  {
    title: "健康記錄", //'TANITA',
    path: "/tanita-import",
    icon: <FitnessCenterIcon />,
  },

  {
    title: "共創排行榜", // 'LEADERBOARD',
    path: "/cosport-ranking",
    icon: <LeaderboardIcon />,
  },
  {
    title: "共創數據綜覽", // 'LEADERBOARD',
    path: "/cosport-analytics",
    icon: <StackedBarChartIcon />,
  },
  {
    title: "排行榜資料", // 'LEADERBOARD',
    path: "/adminLeaderboard",
    icon: <MilitaryTechIcon />,
  },
  {
    title: "獎勵任務設定", // 'LEADERBOARD',
    path: "/organization/mission_settings",
    icon: <StarsIcon />,
  },
  {
    title: "活動統計", // 'LEADERBOARD',
    path: "/adminStatistic",
    icon: <EqualizerIcon />,
  },

  {
    title: "數據查詢", // 'LEADERBOARD',
    path: "/adminDataQuery",
    icon: <SearchIcon />,
  },
  {
    title: "MEMBER QRCODE",
    path: "/member-qr",
    icon: <QrCodeIcon />,
  },
  {
    title: "更新會員密碼", // 'LEADERBOARD',
    path: "/change-member-password",
    icon: <PasswordIcon />,
  }
  //
  // {
  //   title: 'test',
  //   path: '/test/simple',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

